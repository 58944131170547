import plugin_vue3_A0OWXRrUgq from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import breadcrumb_NUTPyfcBQR from "/vercel/path0/node_modules/@bootstrap-vue-next/nuxt/dist/runtime/breadcrumb.mjs";
import templates_pwa_client_15bedddc_j9xTpfLwQp from "/vercel/path0/.nuxt/templates.pwa.client.15bedddc.ts";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import google_analytics_pJGHEUVnWz from "/vercel/path0/plugins/google-analytics.js";
import pinia_Uphuq97G1L from "/vercel/path0/plugins/pinia.js";
import scrollto_hI03cYU8FB from "/vercel/path0/plugins/scrollto.js";
import vue_social_client_u2JpUqMYr8 from "/vercel/path0/plugins/vue-social.client.js";
export default [
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  breadcrumb_NUTPyfcBQR,
  templates_pwa_client_15bedddc_j9xTpfLwQp,
  chunk_reload_client_UciE0i6zes,
  payload_client_yVLowv6hDl,
  google_analytics_pJGHEUVnWz,
  pinia_Uphuq97G1L,
  scrollto_hI03cYU8FB,
  vue_social_client_u2JpUqMYr8
]