import { useRouter } from '#app';
import VueAnalytics, { trackRouter } from 'vue-gtag-next';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(VueAnalytics, {
    property: {
      id: 'UA-135770317-1',
      set: [{ field: 'anonymizeIp', value: true }],
    }
  });
  onNuxtReady(() => {
    trackRouter(useRouter());
  })
});
