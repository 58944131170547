export const TEAM = [
  {
    highlight: true,
    name: 'Kamil Mysliwiec',
    firstName: 'Kamil',
    title: 'Co-Founder',
    twitter: 'kammysliwiec',
    github: 'kamilmysliwiec',
    linkedin: 'kamil-mysliwiec-992bbb105',
    thumbnail: `/assets/photos-v2/kamil.png`,
    photo: `/assets/photos-v2/kamil.png`,
    photoLarge: `/assets/photos/kamilmysliwiec.png`,
    description:
      '<a href="https://nestjs.com/" rel="noopener" target="_blank">NestJS</a> Framework Creator<br> Google Developer Expert<br> International Speaker',
    bio: `
        Kamil Myśliwiec is a Google Developer Expert (GDE) in Web Technologies and Angular.
        Creator of NestJS, Co-Founder of Trilon.io, open source contributor, and Full-Stack developer with a knack for different languages and software development techniques.
      `,
    videos: [],
  },
  {
    highlight: true,
    name: 'Mark Pieszak',
    firstName: 'Mark',
    title: 'Co-Founder',
    twitter: 'markpieszak',
    github: 'markpieszak',
    linkedin: 'mark-pieszak',
    thumbnail: `/assets/photos-v2/mark.png`,
    photo: `/assets/photos-v2/mark.png`,
    photoLarge: `/assets/photos/markpieszak.png`,
    description:
      '<a href="https://github.com/angular/universal#universal-team" rel="noopener" target="_blank">Angular Universal</a> team<br> NestJS Core Team member<br> International Speaker',
    bio: `
        Mark has been in the industry for over 20 years, and has had been in charge of everything from UI Design, to fullstack development, and everything in between.
        With a huge passion for Open Source he has also been a part of the Angular Universal team since 2015 and a large contributor to ASP.NET, Azure, NestJS and other projects.
      `,
    videos: [],
  },
  {
    name: 'Rick Dutour Geerling',
    firstName: 'Rick',
    title: 'Lead Software Architect',
    github: 'tuxmachine',
    thumbnail: `/assets/photos-v2/rick.png`,
    photo: `/assets/photos-v2/rick.png`,
    bio: `
    Rick is a pragmatic and solution driven engineer with plenty of experience developing enterprise solutions. He has a passion for solving complex problems and aims for a deep understanding of the code he’s working with. He values direct communication, good leadership and the occasional bad pun.
      `,
    videos: [],
  },
  {
    name: 'Jay McDoniel',
    firstName: 'Jay',
    title: 'Software Architect',
    github: 'jmcdo29',
    thumbnail: `/assets/photos-v2/jay.png`,
    photo: `/assets/photos-v2/jay.png`,
    bio: `
    Jay is an open source software enthusiast, and a member of the <strong>NestJS</strong> core team. He is also the maintainer of several Nest-centric packages like: nest-commander Ogma, and nestjs-spelunker, as well as providing support for NestJS on Discord in his free time.  He has a passion for writing maintainable code, and for coming up with unique solutions that can help others with similar problems.
      `,
    videos: [],
  },
  {
    name: 'Daniel De Lucca',
    firstName: 'Daniel',
    title: 'Lead Software Architect',
    twitter: 'deluccadev',
    github: 'delucca',
    thumbnail: `/assets/photos-v2/daniel.png`,
    photo: `/assets/photos-v2/daniel.png`,
    bio: `
    Daniel is a tech-savvy open-source enthusiast software architect. Once an entrepreneur, he worked for some of the largest Brazilian unicorns, like QuintoAndar and Neon Bank. He created applications that handled thousands of requests per minute, and was a core contributor for Kubeless and other open-source libraries. Daniel uses NestJS since its early days and has been one of the evangelists of our framework in the Brazillian community.
      `,
    videos: [],
  },
  {
    name: 'Tolga Paksoy',
    firstName: 'Tolga',
    title: 'Software Architect',
    twitter: '',
    github: 'tolgap',
    thumbnail: `/assets/photos-v2/tolga.png`,
    photo: `/assets/photos-v2/tolga.png`,
    bio: `
    With over a decade of experience in everything from fullstack development to Node.js / NestJS, Ruby, PHP, and Shopify app development - Tolga is always learning and pursuing new challenges.  He has created and maintains the shopify-nestjs-api and nestjs-shopify-auth packages, as well as contributing to the NestJS, Shopify and other open source ecosystems whenever possible!
      `,
    videos: [],
  },
//   {
//     name: 'Ilya Moroz',
//     firstName: 'Ilya',
//     title: 'Senior Software Engineer',
//     github: 'flamewow',
//     thumbnail: `/assets/photos-v2/ilya.png`,
//     photo: `/assets/photos-v2/ilya.png`,
//     bio: `
//     Ilya is a tech enthusiast with a broad spectrum of experience in different software engineering spheres, with the passion for  architecture & backend development.
// A huge open source fan, Ilya supports various NestJS communities for NestJS in his spare time and is the creator of the nestjs-asyncapi package.
// He is a pragmatic developer who loves improving Developer Experience, and building clean, maintainable, DRY code.
//       `,
//     videos: [],
//   },
  {
    name: 'Michael Hladky',
    firstName: 'Michael',
    title: 'Lead Instructor',
    twitter: 'michael_hladky',
    github: 'BioPhoton',
    thumbnail: `/assets/photos-v2/michael.png`,
    photo: `/assets/photos-v2/michael.png`,
    description:
      '<a href="https://github.com/angular/universal#universal-team" rel="noopener" target="_blank">Angular Universal</a> team<br> Creator of AS.NET Vue & ASP.NET Angular<br> International Speaker<br> Educator',
    // angular-college.com, @angularVienna, angular-austria.at
    bio: `
    Michael Hladky is a Google Developer Expert (GDE), Microsoft MVP, trainer, and consultant with a focus on Angular and RxJS. For years he has helped companies and developers to setup scalable architectures and performant processes enabling teams to keep up with state of the art development.
    A vibrant member of the tech community, he organizes multiple community events and workshops each year to give back.
      `,
    videos: [],
  },
  {
    name: 'Davide Gheri',
    firstName: 'Davide',
    title: 'Senior Software Engineer',
    github: 'Davide-Gheri',
    thumbnail: `/assets/photos-v2/davide.png`,
    photo: `/assets/photos-v2/davide.png`,
    bio: `
    Davide has been a contributor to open source and especially the NestJS ecosystem since the very beginning. He has created & currently maintains several NestJS modules such as the Mercurius GraphQL server integration, Algolia search, and OAuth2 integrations. He is always learning and striving to create scalable, maintainable, and well designed systems.
      `,
    videos: [],
  },
  {
    name: 'Manuel Herrera',
    firstName: 'Manuel',
    title: 'Senior Software Engineer',
    github: 'notusertelken',
    thumbnail: `/assets/photos-v2/manuel-h.png`,
    photo: `/assets/photos-v2/manuel-h.png`,
    bio: `
    Manuel constantly strives to have a deeper understanding of technology. He has wide range of experience, from networking, fullstack development to architecture and engineering management. He has a passion for creating futureproof systems by first getting a strong understanding of a business' long-term strategy. With a history of working in cyber security, he is constantly thinking about how best to secure applications from future potential attacks.
      `,
    videos: [],
  },
  {
    name: 'Manuel Carballido',
    firstName: 'Manuel',
    title: 'Senior Software Engineer',
    github: 'mcarballido',
    thumbnail: `/assets/photos-v2/manuel-2.png`,
    photo: `/assets/photos-v2/manuel-2.png`,
    bio: `
    Manuel is a tech enthusiast with years of experience as a full-stack developer.
    He has a very keen eye for detail and is a preacher of clean code as he puts a lot of attention
    into promoting and writing performant, scalable, and maintainable software.
    He is pragmatic and knows how to improve not only the code itself but the development process as a whole.
      `,
    videos: [],
  },
  {
    name: 'Camila Nery',
    firstName: 'Camila',
    title: 'Senior Software Engineer',
    github: 'camilacnery',
    twitter: 'camilanery',
    thumbnail: `/assets/photos-v2/camila.png`,
    photo: `/assets/photos-v2/camila.png`,
    bio: `
    Camila is a software developer that loves a good challenge, her motivation comes from diving into the unknown and figuring things out along the way.
During her career she has worked for companies in many different industries, creating all sorts of web applications such as a recruitment portal, an airline sales web application, a health content portal, internal admins and even a movie streaming service for Smart TVs. Her current favorite tech topics are: front-end development, testing, performance, user experience and analytics.
      `,
    videos: [],
  },
  {
    name: 'Guilherme Luchesi',
    firstName: 'Guilherme',
    title: 'Senior Software Engineer',
    github: 'guiluchesi',
    thumbnail: `/assets/photos-v2/guilherme.png`,
    photo: `/assets/photos-v2/guilherme.png`,
    bio: `
Guilherme is a front-end enthusiast who loves crafting perfect CSS, focusing on performance, and automation. During his career, he has been through it all, from enterprise websites to an application for a major Brazilian bank. When he was introduced to NestJS he quickly became a huge fan, and has been spreading the word ever since.
      `,
    videos: [],
  },
  {
    name: 'Taimoor Farras',
    firstName: 'Taimoor',
    title: 'Senior Software Engineer',
    github: 'tfarras',
    thumbnail: `/assets/photos-v2/taimoor.png`,
    photo: `/assets/photos-v2/taimoor.png`,
    bio: `
Taimoor has been working in the tech industry for over 5 years as a fullstack developer and tech lead. He is a passionate software engineer with a focus on NestJS and IoT. Author of several small packages for NestJS and has given a talk about NestJS at the Moldova Developer Conference 2021. In his free time, he enjoys staying up-to-date with the latest technologies.
      `,
    videos: [],
  },
  {
    name: 'Mirsad Halilčević',
    firstName: 'Mirsad',
    title: 'Senior Software Engineer',
    github: 'sixaphone',
    thumbnail: `/assets/photos-v2/mirsad.png`,
    photo: `/assets/photos-v2/mirsad.png`,
    bio: `
An experienced and versatile software developer with expertise in full-stack development, testing, deployment, and maintenance. Proficient in mentoring, team leadership, and quick to adapt to new technologies and evolving business needs.
      `,
    videos: [],
  },
  {
    name: 'Henrique Weiand',
    firstName: 'Henrique',
    title: 'Senior Software Engineer',
    github: 'henriqueweiand',
    twitter: 'henrique_weiand',
    thumbnail: `/assets/photos-v2/henrique.png`,
    photo: `/assets/photos-v2/henrique.png`,
    bio: `
A software engineer passionate about leveraging new technologies to create innovative solutions. With a strong background in development, his expertise lies in crafting efficient and scalable code to solve complex challenges. He thrives in the development world, constantly seeking out the latest trends and methodologies to stay at the forefront of the industry.
      `,
    videos: [],
  },
  /*{
    name: 'Jan-Niklas Wortmann',
    firstName: 'Jan-Niklas',
    title: 'Collaborator',
    twitter: 'niklas_wortmann',
    github: 'niklas-wortmann',
    thumbnail: `/assets/photos-v2/jan-niklas.png`,
    photo: `/assets/photos-v2/jan-niklas.png`,
    bio: `
    Jan-Niklas Wortmann is an enterprise consultant with vast experience designing and implementing large scaled applications. He has a passion for developing clean and maintainable code, with unparalleled expertise in Angular and reactive programming paradigms. Jan is also a member of the RxJs Core Team, giving back to the community whenever he can.
      `,
    videos: []
  },*/
  {
    name: 'Jakub Staron',
    firstName: 'Jakub',
    title: 'Creative Director',
    twitter: '',
    social: [
      {
        id: 1,
        name: 'jakubstaron',
        link: 'https://www.behance.net/jakubstaron',
        icon: 'fa-behance',
      },
      {
        id: 2,
        name: 'jakubstaron',
        link: 'https://dribbble.com/JakubStaron',
        icon: 'fa-dribbble',
      },
    ],
    external_link: 'https://www.behance.net/jakubstaron',
    external_link_name: 'jakubstaron',
    thumbnail: `/assets/photos-v2/jakub.png`,
    photo: `/assets/photos-v2/jakub.png`,
    description:
      '<a href="https://github.com/angular/universal#universal-team" rel="noopener" target="_blank">Angular Universal</a> team<br> Creator of ASP.NET Vue & ASP.NET Angular<br> International Speaker<br> Educator',
    bio: `
      With almost a decade of experience crafting world-class designs for everything from startups to enterprise.
    `,
    images: [
      {
        title: 'Trilon - Design, business cards',
        thumbnail: `/assets/jakub-trilon.png`,
        url: 'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/b1f0b377333145.5c8bb45db96cf.png',
        date: 'January 2, 2019',
      },
      {
        title: 'Speedtest - Design',
        thumbnail: `/assets/jakub-speedtest.png`,
        url: 'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/5cdd9469803975.5c1cf9e542991.png',
        date: 'February 13, 2019',
      },
      {
        title: 'Marketing website - Design',
        thumbnail: `/assets/jakub-formql.png`,
        url: 'https://dribbble.com/shots/6505537-Cleaning-company',
        date: 'August 29, 2019',
      },
    ],
    videos: [],
  },
];
