import { default as _91_46_46_46slug_93QvcqcC3xvKMeta } from "/vercel/path0/pages/blog/[...slug].vue?macro=true";
import { default as indexmfxAx2eWMBMeta } from "/vercel/path0/pages/blog/index.vue?macro=true";
import { default as careers4lXeegTIcrMeta } from "/vercel/path0/pages/careers.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as servicesoiqz5GGY44Meta } from "/vercel/path0/pages/services.vue?macro=true";
import { default as teamThGqkFwfKIMeta } from "/vercel/path0/pages/team.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93QvcqcC3xvKMeta?.name ?? "blog-slug",
    path: _91_46_46_46slug_93QvcqcC3xvKMeta?.path ?? "/blog/:slug(.*)*",
    meta: _91_46_46_46slug_93QvcqcC3xvKMeta || {},
    alias: _91_46_46_46slug_93QvcqcC3xvKMeta?.alias || [],
    redirect: _91_46_46_46slug_93QvcqcC3xvKMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/blog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexmfxAx2eWMBMeta?.name ?? "blog",
    path: indexmfxAx2eWMBMeta?.path ?? "/blog",
    url: "/blog",
    meta: indexmfxAx2eWMBMeta || {},
    alias: indexmfxAx2eWMBMeta?.alias || [],
    redirect: indexmfxAx2eWMBMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: careers4lXeegTIcrMeta?.name ?? "careers",
    path: careers4lXeegTIcrMeta?.path ?? "/careers",
    url: "/careers",
    meta: careers4lXeegTIcrMeta || {},
    alias: careers4lXeegTIcrMeta?.alias || [],
    redirect: careers4lXeegTIcrMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/careers.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    url: "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: servicesoiqz5GGY44Meta?.name ?? "services",
    path: servicesoiqz5GGY44Meta?.path ?? "/services",
    url: "/services",
    meta: servicesoiqz5GGY44Meta || {},
    alias: servicesoiqz5GGY44Meta?.alias || [],
    redirect: servicesoiqz5GGY44Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/services.vue").then(m => m.default || m)
  },
  {
    name: teamThGqkFwfKIMeta?.name ?? "team",
    path: teamThGqkFwfKIMeta?.path ?? "/team",
    url: "/team",
    meta: teamThGqkFwfKIMeta || {},
    alias: teamThGqkFwfKIMeta?.alias || [],
    redirect: teamThGqkFwfKIMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/team.vue").then(m => m.default || m)
  }
]