const senior = {
  title: `Senior Software Engineer`,
  type: `Full-time`,
  language: 'English (Fluent - Written & Verbal)',
  description: `
    We are looking for an experienced senior software engineer to join our all-remote and growing distributed team.
    <br><br>
    <strong>At Trilon we work with some of todays largest enterprise companies around the world, working with cutting-edge technology and applications.</strong>
    <br><br>
    If you have very strong experience with Node.js (+ ecosystem), Databases (SQL / NoSQL), GraphQL/REST, love TypeScript/JavaScript, and of course - love NestJS - we'd love to hear from you!
    <br><br>
    Work-life balance is important to us at Trilon. So work the hours that you enjoy the most, whether that's in the morning, afternoon or evening.  After all, life is not all about work! So we make sure to carve out at least 1 day per week for open source / writing articles / anything else fun as well! Give back to the community and help us improve amazing open-source projects like NestJS, and many others.
    <br><br>
    If you love what you do, and always want to learn more, let's chat!
  `,
  skillsRequired: [
    `Fluent English language skills (verbal and written)`,
    `Strong backend programming background (ie: Node.js)`,
    `5+ years experience in web application development`,
    `Great interpersonal skills - we are a close knit team and work together to solve problems`,
    `Great problem solver and ability to reinvent oneself`,
    `High level of proficiency using JavaScript and TypeScript`,
    `Working knowledge of continuous development principles, practices, and tools`,
    `Thorough understanding of scope and tradeoffs of micro-service architecture`,
    `Proficient computer science fundamentals`,
    `Independent and efficient time management`,
  ],
  niceToHave: [
    `Experiencing working with NestJS & surrounding Node.js ecosystem`,
    `Experience with specific cloud providers (AWS | GCP | Azure)`,
    `Experience with front-end frameworks (Angular | React | Vue | etc)`,
    `Previous experience working remotely`,
    `Passionate about learning - You’ll help identify new technologies and approaches to problems`,
    `Experience working directly with clients or product owners.`,
  ],
  responsibilities: [
    `Bring your NestJS / Node.js and ecosystem expertise to work on cutting-edge products and applications`,
    `Work remotely, work the hours that work best for you!`,
    `Help build applications that make the most out of NestJS, improving Nest and ecosystem, all along the way.`,
    `Time to give back to the open-source community (NestJS, and others).`,
    `The opportunity (if you would like) to represent Trilon & NestJS in community events like conferences, podcasts, meetups, etc.`,
  ],
};

export const JOBS = [
  {
    id: 1,
    location: 'USA / Canada (remote)',
    titleNoTags: `Senior Software Engineer - Remote - (USA/Canada)`,
    title: senior.title,
    type: senior.type,
    language: senior.language,
    description: senior.description,
    skillsRequired: senior.skillsRequired,
    niceToHave: senior.niceToHave,
    responsibilities: senior.responsibilities,
  },
  {
    id: 2,
    location: `Europe (remote)`,
    titleNoTags: `Senior Software Engineer - Remote - Europe`,
    title: senior.title,
    type: senior.type,
    language: senior.language,
    description: senior.description,
    skillsRequired: senior.skillsRequired,
    niceToHave: [
      `Fluent in other languages (German | French)`,
      ...senior.niceToHave,
    ],
    responsibilities: senior.responsibilities,
  },
];
