const youtubeEmbedOptions = '?controls=1&modestbranding=1&rel=0';

export const CONFERENCE_TALKS = [
  {
    id: 0,
    name: 'Kamil Mysliwiec',
    conference: 'JsKongress 2019 - Munich, Germany',
    talkName: 'Taming reactive Node.js: Stream-oriented architecture with Nest',
    youtubeId: `c8hvW14VdkY${youtubeEmbedOptions}&start=0`,
    thumbnail: '/assets/kamil-mysliwiec-jscamp.png',
  },
  {
    id: 1,
    name: 'Mark Pieszak',
    conference: 'Plone Conf 2017 Keynote - Barcelona, Spain',
    talkName:
      'Rendering JavaScript on the Server?<br>Welcome to Angular Universal',
    youtubeId: `8hN6cMqmrUY${youtubeEmbedOptions}&start=0`,
    thumbnail: '/assets/mark-pieszak-ploneconf-keynote.jpg',
  },
  {
    id: 2,
    name: 'Kamil Mysliwiec',
    conference: 'HolyJS 2018 - Moscow, Russia',
    talkName: 'Revealing framework fundamentals: NestJS behind the curtain',
    youtubeId: `jo-1EUxMmxc${youtubeEmbedOptions}&start=0`,
    thumbnail: '/assets/kamil-mysliwiec-holyjs.jpg',
  },
  {
    id: 3,
    name: 'Michael Hladky',
    conference: 'Angular Connect 2018 - London, England',
    talkName: 'RxJS schedulers in depth',
    youtubeId: `S1eDh7MonbI${youtubeEmbedOptions}&start=0`,
    thumbnail: '/assets/michael-hladky-angularconnect.png',
  },
  {
    id: 4,
    name: 'Olivier Combe',
    conference: 'Angular Connect 2018 - London, England',
    talkName: 'Runtime i18n with Ivy',
    youtubeId: `miG-ghJhFPc${youtubeEmbedOptions}&start=0`,
    thumbnail: '/assets/olivier-combe-i18n.png',
  },
  {
    id: 5,
    name: 'Kamil Mysliwiec',
    conference: 'NG-DE 2019 - Berlin, Germany',
    talkName: 'Demystifying Dependency Injection: Angular vs NestJS',
    youtubeId: `vYFhHVMetPg${youtubeEmbedOptions}&start=0`,
    thumbnail: '/assets/kamil-mysliwiec-ngde.png',
    homepage: false,
  },
  {
    id: 6,
    name: 'Kamil Mysliwiec',
    conference: 'JsPoland 2018 - Warsaw, Poland',
    talkName: 'Building a platform: NestJS from the ground up',
    youtubeId: `f0qzBkAQ3mk${youtubeEmbedOptions}&start=0`,
    thumbnail: '/assets/kamil-mysliwiec-jspoland.png',
    homepage: false,
  },
  {
    id: 7,
    name: 'Kamil Mysliwiec',
    conference: 'ngConf 2019 - Salt Lake City, USA',
    talkName: 'Productivity Revolution: Angular Principles in Node',
    youtubeId: `RSIX4Olo2Vg${youtubeEmbedOptions}&start=0`,
    thumbnail: '/assets/kamil-mysliwiec-ngconf.png',
    homepage: false,
  },
  {
    id: 8,
    name: 'Michael Hladky',
    conference: 'JsKongress 2019 - Munich, Germany',
    talkName: 'Crafting state management – A principle based architecture',
    youtubeId: `eXNph3xMRWY${youtubeEmbedOptions}&start=0`,
    thumbnail: '/assets/michael-hladky-ngconf.png',
    homepage: false,
  },
  {
    id: 9,
    name: 'Michael Hladky',
    conference: 'Angular Vienna 2019 - Vienna, Austria',
    talkName: 'Angular and RxJS - Tackling Component State Reactively',
    youtubeId: `I8uaHMs8rw0${youtubeEmbedOptions}&start=0`,
    thumbnail: '/assets/michael-hladky-ngtalks.png',
    homepage: false,
  },
  {
    id: 10,
    name: 'Jan-Niklas Wortmann',
    conference: 'JSDC 2020 - Taipei, Taiwan',
    talkName: 'RxJS - Practice makes Perfect',
    youtubeId: `cSkJdSBAm1Q${youtubeEmbedOptions}&start=0`,
    thumbnail: '/assets/jan-niklas-jsdc.png',
    homepage: false,
  },
  {
    id: 11,
    name: 'Jan-Niklas Wortmann',
    conference: 'ngAir 218 - Remote',
    talkName: 'Fix Asynchrony with RxJS',
    youtubeId: `Mws9J5N1zjA${youtubeEmbedOptions}&start=0`,
    thumbnail: '/assets/jan-niklas-angularair.png',
    homepage: false,
  },
  {
    id: 12,
    name: 'Max Koretskyi',
    conference: 'Angular Connect 2019 - London, England',
    talkName: 'The secrets behind Angular’s lightning speed',
    youtubeId: `nQ8oJ1rpwIc${youtubeEmbedOptions}&start=0`,
    thumbnail: '/assets/max-koretskyi-angularconnect-2.png',
    homepage: false,
  },
  {
    id: 13,
    name: 'Max Koretskyi',
    conference: 'Angular Connect 2018 - London, England',
    talkName: 'Optimize and debug change detection like a pro',
    youtubeId: `DsBy9O0c6eo${youtubeEmbedOptions}&start=0`,
    thumbnail: '/assets/max-koretskyi-angularconnect-1.png',
    homepage: false,
  },
  {
    id: 14,
    name: 'Max Koretskyi',
    conference: 'NgConf 2018 - Salt Lake City, USA',
    talkName: 'Modules are not what you think they are',
    youtubeId: `pERhnBBae2k${youtubeEmbedOptions}&start=0`,
    thumbnail: '/assets/max-koretskyi-ngconf.png',
    homepage: false,
  },
  {
    id: 15,
    name: 'Olivier Combe',
    conference: 'Angular Connect 2017 - London, England',
    talkName: 'Internationalization, New and Upcoming Features',
    youtubeId: `GRHyyjjJeKo${youtubeEmbedOptions}&start=0`,
    thumbnail: '/assets/olivier-combe-angularconnect-2017.png',
    homepage: false,
  },
  {
    id: 16,
    name: 'Mark Pieszak',
    conference: 'ngPoland 2020 - Warsaw, Poland',
    talkName:
      'Keynote Session:<br>Rethinking Angular Architecture & Performance',
    youtubeId: `C6sKq8eEbtY${youtubeEmbedOptions}&start=0`,
    thumbnail: '/assets/mark-pieszak-ngpoland-keynote.png',
    homepage: true,
  },
  {
    id: 17,
    name: 'Taimoor Farras',
    conference: 'Moldova Developer Conference 2021 - Chisinau, Moldova',
    talkName: 'NestJS - new king in Node.js backend development',
    youtubeId: `EmDyKcrjEy0${youtubeEmbedOptions}&start=0`,
    thumbnail: '/assets/taimoor-mdc.png',
    homepage: false,
  },
];
