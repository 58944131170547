export const TAGS = {
  Angular: 'Angular',
  AngularCLI: 'AngularCLI',
  AngularUniversal: 'AngularUniversal',
  Auth: 'Authentication',
  VSCode: 'VSCode',
  Debugging: 'Debugging',
  NestJS: 'NestJS',
  NodeJS: 'NodeJS',
  Cloud: 'Cloud',
  Zeit: 'Zeit',
  Now: 'Now',
  Deployments: 'Deployments',
  SEO: 'SEO',
  SSR: 'SSR',
  Serverless: 'Serverless',
  Azure: 'Azure',
  NPM: 'NPM',
  Patterns: 'Patterns',
  Productivity: 'Productivity',
  Laravel: 'Laravel',
  Microservices: 'Microservices',
  Redis: 'Redis',
  RxJS: 'RxJS',
  ReactiveProgramming: 'ReactiveProgramming',
  Courses: 'Courses',
  JavaScript: 'JavaScript',
  Tests: 'Tests',
  Mocks: 'Mocks',
  Swagger: 'Swagger',
  Documentation: 'Documentation',
  TDD: 'TDD',
};
